<template>
  <div id="organizationSettingsPage">
    <!-- <iframe src="https://settings.growlytics.in"></iframe> -->
    <iframe v-if="iframeUrl" :src="iframeUrl"></iframe>
  </div>
</template>

<script>
import organizationSettingsComponent from './organizationSettingsComponent';
export default organizationSettingsComponent;
</script>

<style lang="scss" src="./organizationSettings.scss"></style>
